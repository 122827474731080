.footer {
    background-color: #ffffff;
    display: grid;
    row-gap: 25px;
    grid-template-columns: repeat(6,1fr);

  }

  .footer--kunteynir {
    grid-column: 2/6;
    grid-template-columns: repeat(4,1fr);
    display: grid;
    row-gap: 25px;
    margin-top: 50px;
    }
  

  .footer--info {
  row-gap: 25px;
  display: grid;
  grid-column: 2/6;
  }
  
  .footer--img {
    grid-column: 3/5;
    width: 60px;
    margin-left: auto;
    margin-right: auto;
    }
  
  .media_block--footer {
    grid-column: 2/4;
    grid-template-columns: repeat(4,75px);
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    }
  
  
  .footer-text {
    font-family: "open-sans-light";
    font-size: 14px;
  }
  .footer-text p {
    font-family: "open-sans-light";
    font-size: 14px;
  }
  
  .footer-text li {
    list-style: none;
    padding: 5px;
    transition: 500ms;
    font-family: "open-sans-light";
    color: #1937b9;
  }
  
  .footer-text a {
    list-style: none;
    padding: 5px;
    transition: 500ms;
    font-family: "open-sans-light";
    color: #1937b9;
  }
  
  .footer-text li.footer-h {
    color: #2f2f2f;
    font-family: open-sans-semibold;
  }
  
  a.footer-h {
    color: #2f2f2f;
    font-family: open-sans-semibold;
  }
  
  a.footer-h:hover {
    color: #1937b9;
  }
  
  .footer-text li.footer-h:hover {
    color: #1937b9;
  }
  
  .footer-text li:hover {
    color: #2f2f2f;
    cursor: pointer;
    transition: 500ms;
  }
  
  .footer-text a:hover {
    color: #2f2f2f;
    cursor: pointer;
    transition: 500ms;
  }
  
  .footer--lastimg {
  margin-bottom: 25px;
  width: 230px;
  max-height: 16px;
  margin-left: auto;
  margin-right: auto;
  grid-column: 2/6;
  }
  
  .hreftocatalog {
    color: #1937b9;
    font-style: italic;
    transition: 500ms;
  }
  
  .hreftocatalog:hover {
    opacity: 0.8;
    cursor: pointer;
    transition: 500ms;
  }