.footer {
  background-color: #fff;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 25px;
  display: grid;
}

.footer--kunteynir {
  grid-column: 2 / 6;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 25px;
  margin-top: 50px;
  display: grid;
}

.footer--info {
  grid-column: 2 / 6;
  row-gap: 25px;
  display: grid;
}

.footer--img {
  grid-column: 3 / 5;
  width: 60px;
  margin-left: auto;
  margin-right: auto;
}

.media_block--footer {
  grid-column: 2 / 4;
  grid-template-columns: repeat(4, 75px);
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.footer-text, .footer-text p {
  font-family: open-sans-light;
  font-size: 14px;
}

.footer-text li, .footer-text a {
  color: #1937b9;
  padding: 5px;
  font-family: open-sans-light;
  list-style: none;
  transition: all .5s;
}

.footer-text li.footer-h, a.footer-h {
  color: #2f2f2f;
  font-family: open-sans-semibold;
}

a.footer-h:hover, .footer-text li.footer-h:hover {
  color: #1937b9;
}

.footer-text li:hover, .footer-text a:hover {
  color: #2f2f2f;
  cursor: pointer;
  transition: all .5s;
}

.footer--lastimg {
  grid-column: 2 / 6;
  width: 230px;
  max-height: 16px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}

.hreftocatalog {
  color: #1937b9;
  font-style: italic;
  transition: all .5s;
}

.hreftocatalog:hover {
  opacity: .8;
  cursor: pointer;
  transition: all .5s;
}

/*# sourceMappingURL=index.56fceef4.css.map */
